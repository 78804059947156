import React, { useEffect, useState } from 'react'
import * as st from '@assets/styl/Modal.module.styl'
import cn from 'classnames'

type ModalProps = {
  children: React.ReactNode,
  open?: boolean,
  close: () => void,
  className?: string
}

export default ({ children, open, close, className }: ModalProps) => {
  const [active, setActive] = useState(false)
  const [visible, setVisible] = useState(false)

  useEffect(() => {
    if (open) {
      setActive(true)
      setTimeout(() => setVisible(true), 50)
    } else {
      setVisible(false)
      setTimeout(() => setActive(false), 300)
    }
  }, [open])

  return <div className={cn(st.core, active && st.active, visible && st.visible)}>
    <div className={cn(className)}>
      <button className="icon-close-modal" onClick={close}></button>
      {children}
    </div>
  </div>
}