import React from 'react'
import * as st from '@assets/styl/Grid.module.styl'
import cn from 'classnames'

type GridProps = {
  children: any,
  wide?: boolean,
  className?: string
}

export default ({ children, wide, className }: GridProps) =>
  <div className={cn(st.core, wide && st.wide, className)}>
    <div>{children}</div>
  </div>