exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-ajuda-lp-slug-tsx": () => import("./../../../src/pages/ajuda/[lp_slug].tsx" /* webpackChunkName: "component---src-pages-ajuda-lp-slug-tsx" */),
  "component---src-pages-ajuda-tsx": () => import("./../../../src/pages/ajuda.tsx" /* webpackChunkName: "component---src-pages-ajuda-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-politica-de-privacidade-tsx": () => import("./../../../src/pages/politica-de-privacidade.tsx" /* webpackChunkName: "component---src-pages-politica-de-privacidade-tsx" */),
  "component---src-pages-pontos-de-recarga-tsx": () => import("./../../../src/pages/pontos-de-recarga.tsx" /* webpackChunkName: "component---src-pages-pontos-de-recarga-tsx" */),
  "component---src-pages-sucesso-tsx": () => import("./../../../src/pages/sucesso.tsx" /* webpackChunkName: "component---src-pages-sucesso-tsx" */),
  "component---src-pages-tempo-de-recarga-tsx": () => import("./../../../src/pages/tempo-de-recarga.tsx" /* webpackChunkName: "component---src-pages-tempo-de-recarga-tsx" */),
  "component---src-pages-termos-de-uso-tsx": () => import("./../../../src/pages/termos-de-uso.tsx" /* webpackChunkName: "component---src-pages-termos-de-uso-tsx" */)
}

