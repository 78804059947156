// extracted by mini-css-extract-plugin
export var iconArrow = "Loading-module--icon-arrow--1d25c";
export var iconCities = "Loading-module--icon-cities--2172c";
export var iconClose = "Loading-module--icon-close--083b3";
export var iconCloseModal = "Loading-module--icon-close-modal--9cc10";
export var iconCopy = "Loading-module--icon-copy--403d4";
export var iconEnergy = "Loading-module--icon-energy--3ff87";
export var iconFacebook = "Loading-module--icon-facebook--80a24";
export var iconHamburger = "Loading-module--icon-hamburger--d23e3";
export var iconInstagram = "Loading-module--icon-instagram--6f67a";
export var iconLinkedin = "Loading-module--icon-linkedin--ffea4";
export var iconListItem = "Loading-module--icon-list-item--37399";
export var iconLogo = "Loading-module--icon-logo--7fd1a";
export var iconMinus = "Loading-module--icon-minus--a97aa";
export var iconPin = "Loading-module--icon-pin--2495e";
export var iconPlus = "Loading-module--icon-plus--f30bb";
export var iconRecharges = "Loading-module--icon-recharges--f3c7e";
export var iconStations = "Loading-module--icon-stations--da403";
export var iconUsers = "Loading-module--icon-users--dea96";
export var iconWallbox = "Loading-module--icon-wallbox--c06a6";
export var iconWhatsapp = "Loading-module--icon-whatsapp--64760";
export var iconYoutube = "Loading-module--icon-youtube--e14fe";
export var loading = "Loading-module--loading--2ea9f";
export var path1 = "Loading-module--path1--2b20c";
export var path10 = "Loading-module--path10--22391";
export var path11 = "Loading-module--path11--21c85";
export var path12 = "Loading-module--path12--158d6";
export var path13 = "Loading-module--path13--de893";
export var path14 = "Loading-module--path14--2bbf0";
export var path15 = "Loading-module--path15--98359";
export var path2 = "Loading-module--path2--92f26";
export var path3 = "Loading-module--path3--c6cba";
export var path4 = "Loading-module--path4--01d53";
export var path5 = "Loading-module--path5--45dbc";
export var path6 = "Loading-module--path6--139b3";
export var path7 = "Loading-module--path7--36e1c";
export var path8 = "Loading-module--path8--b4119";
export var path9 = "Loading-module--path9--dbefa";
export var spin = "Loading-module--spin--6dd1f";