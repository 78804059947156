import React, { useRef, useState } from 'react'
import axios from 'axios'
import CSS from 'csstype'
import InputMask from 'react-input-mask'
import { Link, navigate, useStaticQuery, graphql } from 'gatsby'
import { Button, Grid, ViewMore } from '@components'
import dataLayerGlobal from '@utils/dataLayerGlobal'
import * as st from '@assets/styl/FAQ.module.styl'
import cn from 'classnames'

type Question = {
  title: string,
  content: string
}

const QuestionLi = ({ title, content: text }: Question) => {
  const defStyle: CSS.Properties = {}
  const [style, setStyle] = useState(defStyle)
  const content = useRef<HTMLParagraphElement>()
  const expand = () => {
    const height = content.current.offsetHeight
    setStyle({ maxHeight: `${height}px` })
    setTimeout(() => setStyle({ maxHeight: 'none' }), 200)
  }
  const contract = () => {
    const height = content.current.offsetHeight
    setStyle({ maxHeight: `${height}px` })
    setTimeout(() => setStyle({ maxHeight: 0 }), 50)
  }
  const onClick = () => {
    if (!style.maxHeight)
      expand()
    else
      contract()
  }

  return <li>
    <h4>
      <span>
        <span onClick={onClick} className="expand-view-more">{title}</span>
      </span>
      <button className={cn(style.maxHeight ? 'icon-minus' : 'icon-plus', 'expand-view-more')} onClick={onClick}></button>
    </h4>
    <div className={st.content} style={style}>
      <p ref={content} dangerouslySetInnerHTML={{ __html: text }}></p>
    </div>
  </li>
}

export default () => {
  const dataLayer = dataLayerGlobal();
  const query = useStaticQuery(graphql`
    query {
      allConfigurations {
        nodes {
          whatsapp
        }
      }
      allQuestions {
        nodes {
          title
          content
        }
      }
    }
  `)
  const config = query.allConfigurations.nodes[0]
  const questions = query.allQuestions.nodes

  const [data, setData] = useState({
    nome: '',
    email: '',
    finalidade: '',
    telefone: '',
    termos: false,
    communications: false,
    identificador: 'Site - Contato',
    legal_bases: [
            {
                'category':'communications',
                'type':'consent',
                'status':'granted'
            },
            {
                'category':'data_processing',
                'type':'consent',
                'status':'granted'
            }
        ],
    token_rdstation: '3e0fe0a9bf5f7cd54281cb9f8997d26a'
  })
  const [buttonLabel, setButtonLabel] = useState('Enviar')
  const onChange = ({ target: { id, value } }) => {
    setData({ ...data, [id]: value })
  }
  const onChangeCheckbox = ({ target: { id, checked } }) => {
    setData({ ...data, [id]: checked })
  }
  const onSubmit = (e: React.FormEvent) => {
    e.preventDefault()
    setButtonLabel('Enviando...')
    axios.post('https://www.rdstation.com.br/api/1.3/conversions', data, {
      headers: {
        'Content-type': 'application/json'
      }
    }).then(data => {
      setButtonLabel('Enviar')
      data.status === 200 && navigate('/sucesso/')
      dataLayer.push({ event: "GA - Eventos", eventCategory: "Conversão", eventLabel: "Formulário de contato" });
    })
  }
  const onWhatsappClick = () => {
    dataLayer.push({ event: "GA - Eventos", eventCategory: "Click WhatsApp", eventLabel: "WhatsApp" });
  }

  return <section className={st.core}>
    <Grid>
      <h3>Perguntas frequentes</h3>
      <p>Selecionamos as principais dúvidas referentes a tecnologia da Wecharge.</p>
      <ViewMore title="perguntas frequentes">
        <ul className={st.questions}>
          {questions.map((question, key) => <QuestionLi {...question} key={key} />)}
        </ul>
      </ViewMore>
      <div className={st.formWrapper} id="contato">
        <div>
          <div className={st.text}>
            <h3>Faça parte desta inovação.</h3>
            <p>Precisando de carregadores elétricos?<br/>Entre em contato e solicite mais informações.</p>
            <Button to={`https://whatsa.me/5508002910045/?t=Ol%C3%A1!%20Vim%20pelo%20site%20da%20WeCharge%20e%20gostaria%20de%20saber%20mais%20informa%C3%A7%C3%B5es`} external color="green" className={st.wppBtn} onClick={onWhatsappClick}>
              <span className="icon-whatsapp"></span>
              Chamar no WhatsApp
            </Button>
          </div>
          <form onSubmit={onSubmit}>
            <div className={st.formGroup}>
              <label htmlFor="nome">Nome</label>
              <input type="text" id="nome" required value={data.nome} onChange={onChange} />
            </div>
            <div className={st.formGroup}>
              <label htmlFor="email">E-mail</label>
              <input type="email" id="email" required value={data.email} onChange={onChange} />
            </div>
            <div className={st.formGroup}>
              <label htmlFor="finalidade">Finalidade do contato</label>
              <select id="finalidade" required value={data.finalidade} onChange={onChange}>
                <option hidden disabled value="">Finalidade do contato</option>
                <option>Carregador para o meu estabelecimento</option>
                <option>Carregador para minha garagem</option>
                <option>Carregador para o meu condomínio</option>
                <option>Carregador para minha frota</option>
                <option>Outra</option>
              </select>
            </div>
            <div className={st.formGroup}>
              <label htmlFor="telefone">Telefone</label>
              <InputMask mask={data.telefone.length > 14 ? "(99) 99999-9999" : "(99) 9999-99999"} maskChar={null} type="tel" id="telefone" required pattern=".{14,15}" value={data.telefone} onChange={onChange} />
            </div>
            <div className={cn(st.formGroup, st.wide, st.checkboxWrapper)}>
              <input type="checkbox" id="termos" required checked={data.termos} onChange={onChangeCheckbox} />
              <label htmlFor="termos">Ao me cadastrar estou de acordo com os <Link to="/termos-de-uso/" target="_blank">termos de uso</Link> e <Link to="/politica-de-privacidade/" target="_blank">política de privacidade</Link>.</label>
            </div>
            <div className={cn(st.formGroup, st.wide, st.checkboxWrapper2)}>
              <input type="checkbox" id="communications" required checked={data.communications} onChange={onChangeCheckbox} />
              <label htmlFor="communications">Autorizo o Grupo Dimas a armazenar meus dados pessoais para enviar campanhas de marketing e informações sobre a empresa nos canais: Telefone, Email e SMS.</label>
            </div>
            <div className={st.btnWrapper}>
              <Button className={st.btn}>{buttonLabel}</Button>
            </div>
          </form>
        </div>
      </div>
    </Grid>
  </section>
}
