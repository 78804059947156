import React from 'react'
import { Grid, Header, HeroAnimation, SearchInput } from '@components'
import * as st from '@assets/styl/Hero.module.styl'
import cn from 'classnames'

type HeroProps = {
  path?: string,
  setLocation?: React.Dispatch<React.SetStateAction<{ lat: number, lng: number }>>
}

export default ({ path, setLocation }: HeroProps) =>
  !path ? // Home hero?

    <section className={st.core}>
      <Header home />
      <Grid className={st.content}>
        <h1>Inovação que move o mundo.</h1>
        <h2>
          Carregamento de veículos elétricos
          <ul>
            <li>&nbsp;confiável</li>
            <li>&nbsp;de qualidade</li>
            <li>&nbsp;com energia limpa</li>
            <li>&nbsp;sempre próximo a você</li>
          </ul>
        </h2>
      </Grid>
      <HeroAnimation />
    </section>

  :

    <section className={cn(st.core, st.formCore)}>
      <div>
        <div className={st.form}>
          <h1>Encontre um eletroposto <b>perto de você!</b></h1>
          <SearchInput setLocation={setLocation} scrollToMap />
        </div>
      </div>
    </section>