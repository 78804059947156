import React, { useEffect, useState } from 'react'
import { Link } from 'gatsby'
import { animateScroll, scroller } from 'react-scroll'
import { Button, Grid, NavList } from '@components'
import * as st from '@assets/styl/Header.module.styl'
import cn from 'classnames'

type HeaderProps = {
  home?: boolean,
  fixed?: boolean
}

export default ({ home, fixed }: HeaderProps) => {
  const [navListActive, setNavListActive] = useState(false)

  if (fixed) {
    var [visible, setVisible] = useState(false)
    useEffect(() => {
      (window as any).setNavListActive = setNavListActive
      if (home) {
        const onScroll = () => setVisible(window.scrollY >= document.getElementById('home-header').offsetHeight)
        onScroll()
        window.addEventListener('scroll', onScroll)
        return () => window.removeEventListener('scroll', onScroll)
      }
    }, [home])
  }

  return <header className={cn(st.core, fixed && st.fixed, home && st.home, visible && st.visible)} id={!fixed && home ? 'home-header' : undefined}>
    <Grid wide className={st.grid}>
      <div className={st.logo}>
        {home && <button
          className="icon-logo"
          onClick={() => animateScroll.scrollToTop({ duration: 500 })}
        ></button>}
        {!home && <Link to="/" className="icon-logo" />}
      </div>
      <NavList home={home} color={fixed ? '' : 'textWhite'} onClose={() => (window as any).setNavListActive(false)} className={cn(st.navList, navListActive && st.active)} />
      {home && <Button to="wecharge" scroll color={fixed ? 'blue' : 'white'} className={st.btn}>Baixe o app</Button>}
      {!home && <Button to="/" onClick={() => setTimeout(() => scroller.scrollTo('wecharge'), 300)} color={fixed ? 'blue' : 'white'} className={st.btn}>Baixe o app</Button>}
      <button className="icon-hamburger" onClick={() => (window as any).setNavListActive(true)}></button>
    </Grid>
  </header>
}