import React, { Dispatch, useState, useEffect } from 'react'
import * as st from '@assets/styl/RechargeTime/CarInformation.module.styl'
import cn from 'classnames'
import { Loading } from '@components'
import { Link } from 'gatsby'
import { LazyImageFull, ImageState } from 'react-lazy-images'
import api from '@api'

interface CarInformationProps {
  result: {
    image: string
    id: number
    name: string
    model: string
    resume_labels: string
    resume_values: string
    slug: string
    brand: {
      name: string
      slug: string
    }
  }
  setStep: Dispatch<React.SetStateAction<number>>
  setQuery: Dispatch<React.SetStateAction<string>>
  brand: string
  loading: boolean
  error: string
  setCarsResult: Dispatch<React.SetStateAction<{}>>
}

const CarInformation = ({ result, setStep, setQuery, brand: brandImage, loading, setCarsResult }: CarInformationProps) => {
  const [brandFiltered, setBrandFiltered] = useState([])
  const urlPage = '/tempo-de-recarga/'
  const { brand, image, model, resume_labels, resume_values } = result

  // const t = () => {
  //   if (brandImage.includes('-') && brandImage === 'swap-e') {
  //     return brandImage
  //   } else if (brandImage.includes('-')) {
  //     return brandImage.replace('-', ' ')
  //   } else {
  //     return brandImage
  //   }
  // }

  const handleEdit = (editName: string) => {
    setStep((prevState) => editName === 'brand' ? prevState - 2 : prevState - 1);
    setCarsResult([]);
    setQuery('');
  };

  const fetchData = async () => {
    try {
      const response = await api('get', 'brands')
      const data = response.result
      setBrandFiltered(data)
    } catch (error) {
      console.error('Ocorreu um erro ao buscar os dados:', error)
    }
  }

  useEffect(() => {
    fetchData()
  }, [brandImage, result])

  const typeBrand = brandFiltered.filter((item) => item.slug === brandImage)

  return (
    <section className={cn('flex-center', st.resultInfoCarSection)}>
      {loading && result && (
        <>
          <div className={cn(st.defaultSection, st.carInformation)}>
            <div className={st.container}>
              <div className={cn(st.carPicture, st.image)}>
                <LazyImageFull src={image} alt={`Imagem do carro ${model}`}>
                  {({ imageState, ref, imageProps: { src } }) => (
                    <div ref={ref}>
                      <img
                        src={imageState === ImageState.LoadSuccess ? src : ''}
                      />
                    </div>
                  )}
                </LazyImageFull>
              </div>
              {brandFiltered.length > 0 ? (
                <img
                  className={cn(st.carLogo, st.image)}
                  width={110}
                  height={65}
                  src={typeBrand[0]?.image}
                />
              ) : (
                loading && <Loading />
              )}
              <div className={st.edit}>
                <p className={cn(st.fontSize, st.carTitle)}>Marca do veículo</p>
                <Link
                  style={{ textDecoration: 'none' }}
                  to={urlPage}
                  className={cn(st.fontSize, st.editButton)}
                  onClick={() => handleEdit('brand')}
                >
                  Editar
                </Link>
              </div>
              <p className={st.carName} style={{ textTransform: 'capitalize' }}>
                {brand?.name.toLowerCase()}
              </p>
              <div className={st.edit}>
                <p className={cn(st.fontSize, st.carTitle)}>Modelo</p>
                <Link
                  style={{ textDecoration: 'none' }}
                  to={`${urlPage}?marca=${brandImage}`}
                  className={cn(st.fontSize, st.editButton)}
                  onClick={() => handleEdit('model')}
                >
                  Editar
                </Link>
              </div>
              <p className={st.carName}>{model}</p>
            </div>
          </div>
          <div className={cn(st.rechargeInformation, st.defaultSection)}>
            <div className={st.labels}>
              <div dangerouslySetInnerHTML={{ __html: resume_labels }}></div>
            </div>

            <div className={st.listContainer}>
              <div dangerouslySetInnerHTML={{ __html: resume_values }}></div>
            </div>
          </div>
        </>
      )}
    </section>
  )
}

export default CarInformation
