import React, { useEffect, useRef } from 'react'
import { RayAnimation } from '@components/CarbonFreeAnimation'
import * as st from '@assets/styl/HeroAnimation.module.styl'

// Manipula a animação dos raios:
class HeroRayAnimation extends RayAnimation {
  start() {
    this.tZero = Date.now()
    this.reverse = Boolean(Math.round(Math.random()))
    requestAnimationFrame(() => this.run())
  }

  move(u: number, index: number) {
    try {
      const length = u * this.track.getTotalLength()
      const p = this.track.getPointAtLength(this.reverse ? this.track.getTotalLength() - length : length)
      this.sprites[index].setAttribute('transform', `translate(${p.x} ${p.y}) rotate(${this.reverse ? '180' : '0'})`)
    } catch(_) {}
  }
}

// Manipula a animação dos raios aleatórios:
class RandomRayAnimation extends RayAnimation {
  speed: number
  tracks: Array<SVGGeometryElement>
  partitions: number
  config: Array<{
    track?: SVGGeometryElement,
    partition: number,
    duration?: number
  }>

  constructor(sprites: Array<SVGGeometryElement>, tracks: Array<SVGGeometryElement>, speed: number, partitions: number = 2) {
    super(sprites, tracks[0], speed)
    this.speed = speed
    this.tracks = tracks
    this.partitions = partitions
    this.start()
  }

  move(u: number, index: number) {
    const { track, partition } = this.config[index]
    const length = (u + partition)/this.partitions * track.getTotalLength()
    const p = track.getPointAtLength(this.reverse ? track.getTotalLength() - length : length)
    this.sprites[index].setAttribute('transform', `translate(${p.x} ${p.y}) rotate(${this.reverse ? '180' : '0'})`)
    if (u <= .3)
      this.sprites[index].setAttribute('opacity', `${u / .3}`)
    else if (u > .7)
      this.sprites[index].setAttribute('opacity', `${1 - (u - .7) / .3}`)
  }

  start() {
    if (!this.tracks) return
    this.reverse = Boolean(Math.round(Math.random()))
    this.config = this.sprites.map(() => ({ partition: 0 }))
    for (let sprite=0; sprite<this.sprites.length; sprite++) {
      this.config[sprite].track = this.tracks[Math.floor(Math.random() * this.tracks.length)]
      this.config[sprite].partition = Math.floor(Math.random() * this.partitions)
      this.config[sprite].duration = this.config[sprite].track.getTotalLength() / this.partitions / this.speed
    }
    setTimeout(() => {
      this.tZero = Date.now()
      requestAnimationFrame(() => this.run())
    }, 500 * Math.floor(Math.random() * 3))
  }

  run() {
    if (this.status) return
    let restart = true
    for (let sprite=0; sprite<this.sprites.length; sprite++) {
      const { duration } = this.config[sprite]
      const u = Math.min((Date.now() - this.tZero) / duration, 1)
      this.move(u, sprite)
      restart &&= u >= 1
    }
    if (restart)
      this.start()
    else
      requestAnimationFrame(() => this.run())
  }
}

export default () => {
  const sprites = Array(9).fill(0).map(() => useRef<SVGGeometryElement>())
  const tracks = Array(17).fill(0).map(() => useRef<SVGLineElement>())
  const animations: Array<RayAnimation> = Array(9).fill(null)

  useEffect(() => {
    for (let i=0; i<6; i++)
      animations[i] = new HeroRayAnimation([sprites[i].current], tracks[i].current, .15)
    animations[6] = new RandomRayAnimation([sprites[6].current], tracks.slice(6, 10).map(t => t.current), .3)
    animations[7] = new RandomRayAnimation([sprites[7].current], tracks.slice(10, 14).map(t => t.current), .3)
    animations[8] = new RandomRayAnimation([sprites[8].current], tracks.slice(14).map(t => t.current), .3, 3)
    return () => {
      for (const animation of animations)
        animation.stop()
    }
  }, [])

  return <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 2560 700" className={st.core}>
    <defs>
      <linearGradient id="Gradiente1" x1="1287.05" y1="1479.14" x2="1288.05" y2="1479.14" gradientTransform="matrix(0, -122.01, -4, 0, 5913.27, 157094.27)" gradientUnits="userSpaceOnUse">
        <stop offset="0" stopColor="#62c6f1" />
        <stop offset="1" stopColor="#62c6f1" stopOpacity="0" />
      </linearGradient>
      <linearGradient id="Gradiente2" x1="1292.58" y1="1765.76" x2="1293.57" y2="1765.76" gradientTransform="matrix(0, -61.01, -2, 0, 3529.43, 78882.15)" gradientUnits="userSpaceOnUse">
        <stop offset="0" stopColor="#fff" />
        <stop offset="1" stopColor="#fff" stopOpacity="0" />
      </linearGradient>
      <clipPath id="Mascara1">
        <polygon points="50 0, 2510 0, 2510 700, 50 700" />
      </clipPath>
    </defs>
    <g>
      <g opacity="0.7">
        <g opacity="0.3">
          <line ref={tracks[3]} x1="2405.3" y1="169.28" x2="353.34" y2="719.1" fill="none" stroke="#fff" strokeWidth="1" />
          <line ref={tracks[2]} x1="1104.41" y1="-581.78" x2="1654.23" y2="1470.17" fill="none" stroke="#fff" strokeWidth="1" />
          <line ref={tracks[1]} x1="2126.94" y1="-25.65" x2="2676.76" y2="2026.3" fill="none" stroke="#fff" strokeWidth="1" />
          <line x1="1364.81" y1="-431.44" x2="-687.15" y2="118.37" fill="none" stroke="#fff" strokeWidth="1" />
          <line ref={tracks[0]} x1="63.92" y1="-1182.51" x2="613.74" y2="869.44" fill="none" stroke="#fff" strokeWidth="1" />
        </g>
        <g opacity="0">
          <path ref={tracks[6]} d="M531.06,4.78l185.76,693.3" fill="none" stroke="red" />
          <path ref={tracks[7]} d="M679.09,1.18,866.59,701" fill="none" stroke="red" />
          <path ref={tracks[8]} d="M826.94-3.11q94.57,352.94,189.14,705.89" fill="none" stroke="red" />
          <path ref={tracks[9]} d="M976.46-1.18q94.65,353.28,189.32,706.56" fill="none" stroke="red" />
          <path ref={tracks[10]} d="M1409.72,1.59,1596.4,698.27" fill="none" stroke="red" />
          <path ref={tracks[11]} d="M1558.26-.13l187,697.79" fill="none" stroke="red" />
          <path ref={tracks[12]} d="M1708,2.61Q1802.14,354,1896.29,705.32" fill="none" stroke="red" />
          <path ref={tracks[13]} d="M1856.75,1.7l187.32,699.06" fill="none" stroke="red" />
          <path ref={tracks[14]} d="M1857.13-.1,232.52,435.48" fill="none" stroke="red" />
          <path ref={tracks[15]} d="M1329.36,1.4,232.52,295.48" fill="none" stroke="red" />
          <path ref={tracks[16]} d="M2557.72,235.78,825.84,700.12" fill="none" stroke="red" />
        </g>
        <g>
          <line  className={st.dashedLine} x1="2246.35" y1="77.52" x2="194.4" y2="627.34" fill="none" stroke="#62c6f1" strokeWidth="1" strokeDasharray="2 2" />
          <line  className={st.dashedLine} x1="945.47" y1="-673.55" x2="1495.29" y2="1378.4" fill="none" stroke="#62c6f1" strokeWidth="1" strokeDasharray="2 2" />
          <line x1="3278.89" y1="673.65" x2="1226.93" y2="1223.47" fill="none" stroke="#62c6f1" strokeWidth="1" strokeDasharray="2 2" />
          <line  className={st.dashedLine} x1="1978" y1="-77.42" x2="2527.82" y2="1974.53" fill="none" stroke="#62c6f1" strokeWidth="1" strokeDasharray="2 2" />
          <line x1="1205.86" y1="-523.21" x2="-846.09" y2="26.61" fill="none" stroke="#62c6f1" strokeWidth="1" strokeDasharray="2 2" />
          <line  className={st.dashedLine} x1="-95.02" y1="-1274.28" x2="454.8" y2="777.67" fill="none" stroke="#62c6f1" strokeWidth="1" strokeDasharray="2 2" />
        </g>
        <line ref={tracks[4]} x1="2660.02" y1="316.35" x2="607.91" y2="866.55" fill="none" stroke="#fff" strokeWidth="1" opacity="0.1" style={{ isolation: 'isolate' }}/>
        <line ref={tracks[5]} x1="2261.12" y1="31.58" x2="232.52" y2="575.48" fill="none" stroke="#fff" strokeWidth="1" opacity="0.1" style={{ isolation: 'isolate' }}/>
      </g>
    </g>

    <g clipPath="url(#Mascara1)">
      {sprites.slice(0, 3).map((ref, key) => <g key={key} ref={ref}>
        <g transform="translate(3 2)">
          <rect x="-6" y="-66.8" width="4" height="122.01" transform="translate(1.36 -1.23) rotate(-15)" fill="url(#Gradiente1)" />
          <path d="M5.73,55.28h5.75l-3.08,10,13.33-13.4H16.5l2.38-9.77Z" fill="#62c6f1" />
        </g>
      </g>)}

      {sprites.slice(3, 6).map((ref, key) => <g key={key} ref={ref}>
        <g transform="translate(-10 -55)">
          <rect x="-6" y="-66.8" width="4" height="122.01" transform="translate(70 43) rotate(75)" fill="url(#Gradiente1)" />
          <path d="M5.73,55.28h5.75l-3.08,10,13.33-13.4H16.5l2.38-9.77Z" transform="rotate(90deg)" fill="#62c6f1" />
        </g>
      </g>)}

      {sprites.slice(6, 8).map((ref, key) => <g key={key} ref={ref}>
        <g>
          <rect x="-3.5" y="-35.34" width="2" height="61.01" transform="translate(1.17 -0.81) rotate(-15)" opacity="0.2" fill="url(#Gradiente2)" style={{ isolation: 'isolate' }}/>
          <path d="M-.63,27.09H3.68L1.37,34.56l10-10H7.44l1.79-7.33Z" fill="#434343" />
        </g>
      </g>)}

      {sprites.slice(8).map((ref, key) => <g key={key} ref={ref}>
        <g>
          <rect x="-3.5" y="-35.34" width="2" height="61.01" transform="translate(30 22) rotate(75)" opacity="0.2" fill="url(#Gradiente2)" style={{ isolation: 'isolate' }}/>
          <path d="M-.63,27.09H3.68L1.37,34.56l10-10H7.44l1.79-7.33Z" transform="rotate(90deg)" fill="#434343" />
        </g>
      </g>)}
    </g>
  </svg>
}