import React from 'react'
import useWindowSize from '@utils/useWindowSize'
import { Button, Grid, Slider } from '@components'
import * as st from '@assets/styl/SegmentsSection.module.styl'
import cn from 'classnames'

const cards = [
  {
    className: st.publicSolutions,
    title: 'Soluções públicas',
    subtitle: 'Abastecimento elétrico de fácil acesso',
    description: <>Carregue seu veículo <b>elétrico ou híbrido</b> em eletropostos e garanta diversos benefícios:</>,
    items: [
      'APP conectado a diversas marcas de carregadores', 'Compatibilidade com veículos elétricos e híbridos de todas as marcas',
      'Fácil localização de eletropostos e recargas diretamente no APP', 'Acompanhamento de recarga em tempo real'
    ],
    buttonLabel: 'Precisando carregar seu veículo?',
    button: <Button to="wecharge" scroll className={st.btn}>Baixe o app</Button>
  },
  {
    className: st.businessSolutions,
    title: 'Soluções empresariais',
    subtitle: 'Carregadores elétricos',
    description: <><b>Faça parte da rede WeCharge</b> e tenha mais economia para o seu negócio:</>,
    items: [
      'Equipamentos premium sem o alto investimento inicial', 'Instalação, manutenção, monitoramento e gestão garantidas',
      'Monetização do carregador', 'Relatórios de consumo de energia e comportamento do cliente'
    ],
    buttonLabel: 'Quer ter a WeCharge em seu estacionamento?',
    button: <Button to="contato" scroll className={st.btn}>Solicite informações</Button>
  },
  {
    className: st.residentialSolutions,
    title: 'Soluções residenciais',
    subtitle: 'Carregadores elétricos domésticos',
    description: <><b>Instale um eletroposto</b> WeCharge em sua casa ou condomínio:</>,
    items: [
      'Transforme sua casa em um eletroposto', 'Conceito de carregamento elétrico moderno e exclusivo',
      'Controle total de sua Wallbox', 'Compatibilidade com veículos elétricos e híbridos de todas as marcas'
    ],
    buttonLabel: 'Quer ter a WeCharge em sua casa?',
    button: <Button to="contato" scroll className={st.btn}>Solicite informações</Button>
  }
]

export default () => {
  const vs = useWindowSize()

  return <section className={st.core} id="solucoes">
    <Grid>
      <h3>Segmentos e soluções</h3>
      <p>Um ponto de carregamento WeCharge para você:</p>
      {(!vs.width || vs.width < 1024) && <Slider overflow={false} finite responsiveSize={1500} classes={{ root: st.slider }}>
        {cards.map((card, key) => <div key={key} className={cn(st.card, card.className)}>
          <h4>{card.title}</h4>
          <p>{card.subtitle}</p>
          <p>{card.description}</p>
          <ul>
            {card.items.map((item, key) => <li key={key}><div><span className="icon-list-item"></span>{item}</div></li>)}
          </ul>
          <p>{card.buttonLabel}</p>
          {card.button}
        </div>)}
      </Slider>}
      {vs.width >= 1024 && <div className={st.cards}>
        {cards.map((card, key) => <div key={key} className={cn(st.card, card.className)}>
          <h4>{card.title}</h4>
          <p>{card.subtitle}</p>
          <p>{card.description}</p>
          <ul>
            {card.items.map((item, key) => <li key={key}><div><span className="icon-list-item"></span>{item}</div></li>)}
          </ul>
          <p>{card.buttonLabel}</p>
          {card.button}
        </div>)}
      </div>}
    </Grid>
  </section>
}