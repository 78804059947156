import React, { useEffect, useState } from 'react'
import { Button, Grid, Slider } from '@components'
import * as st from '@assets/styl/Blog.module.styl'
import requestData from '@api'

type Post = {
  source: string,
  title: string,
  url: string
}

export default () => {
  const [posts, setPosts] = useState<Array<Post>>([])
  useEffect(() => {
    requestData('get', 'articles?max=9999')
      .then(({ result }) => result)
      .then(res => setPosts(res))
  }, [])

  return <section className={st.core}>
    <Grid>
      <h3>Na mídia</h3>
      {Boolean(posts && posts.length) && <Slider classes={{ root: st.slider }}>
        {posts.map(({ source, title, url }, key) => <div key={key} className={st.slide}>
          <h5>{source}</h5>
          <h4>{title}</h4>
          <Button to={url} external color="white" className={st.btn}>Saiba mais</Button>
        </div>)}
      </Slider>}
    </Grid>
  </section>
}