// extracted by mini-css-extract-plugin
export var android = "Footer-module--android--bd0b9";
export var apps = "Footer-module--apps--a049e";
export var carbonFree = "Footer-module--carbonFree--75b74";
export var core = "Footer-module--core--340e5";
export var grid = "Footer-module--grid--c4456";
export var ios = "Footer-module--ios--b8b93";
export var leftBlock = "Footer-module--left-block--6deb7";
export var list = "Footer-module--list--3bcf5";
export var navList = "Footer-module--nav-list--c88ec";
export var rightBlock = "Footer-module--right-block--d3f9f";
export var socials = "Footer-module--socials--a5672";
export var tikiBlock = "Footer-module--tiki-block--d85eb";