import React, { Dispatch, useEffect, useState } from 'react'
import { navigate } from 'gatsby'
import * as st from '@assets/styl/RechargeTime/ResultBrand.module.styl'
import { ListOfItems } from '@components'
import api from '@api'

interface ResultBrandProps {
  step: number
  setLoading: Dispatch<React.SetStateAction<boolean>>
  setStep: Dispatch<React.SetStateAction<number>>
  marca: string
  setMarca: Dispatch<React.SetStateAction<string>>
  setModelo: Dispatch<React.SetStateAction<string>>
  brands: Array<{
    id: number
    name: string
    image: string
    slug: string
    type: string
  }>
}

const ResultBrand = ({
  step,
  setStep,
  brands,
  setMarca,
  marca,
  setLoading,
  setModelo,
}: ResultBrandProps) => {
  const urlPage = '/tempo-de-recarga'
  const [carBrands, setCarBrands] = useState([])
  const [brandsTotal, setBrandsTotal] = useState([])
  
  const fetchData = async () => {
    const data = await api('get', `brands`)
    setBrandsTotal(data.result)
  }

  const handleStep = (id: number, type: string, name: string) => {
    const params = new URLSearchParams(window.location.search)

    if (step !== 3) {
      if (type === 'brand') {
        const brandFiltered = brands.find((car) => car.id === id && car.type === 'brand');
      
        if (brandFiltered) {
          setMarca(brandFiltered.slug)
          params.set('marca', brandFiltered.name.toLowerCase())
          const completeURL = `${urlPage}?marca=${brandFiltered.slug.toLowerCase()}`;
          
          if (completeURL !== urlPage) {
            setLoading(true);
            navigate(completeURL);
          }
        }
      }

      if (type === 'car') {
        const brandImage = brandsTotal.filter((brand) => {
          return name.toLowerCase().includes(brand.name.toLowerCase()) && brand.type === 'brand'
        })

        const carFiltered = brands.find((car) => car.id === id)

        if (carFiltered) {
          setModelo(carFiltered.slug)

          if (brandImage.length > 0) {
            const completeURL = `${urlPage}?marca=${brandImage[0].slug}&modelo=${carFiltered.slug.toLowerCase()}`

            if (completeURL !== urlPage) {
              setLoading(true)
              navigate(completeURL)
              setStep((prevState) => prevState + 1)
            }
          }
        }
      }
    }
  }

  useEffect(() => {
    fetchData()
  }, [brands])

  useEffect(() => {
    if (marca !== '') {
      setStep((previusState: number) => previusState + 1)
    }

    if (marca === '') {
      setCarBrands(brands)
    }
  }, [brands, window.location.search])

  return (
    <ListOfItems
      classNames={[
        st.carContainer,
        st.card,
        st.logoContainer,
        st.infoContainer,
        st.title,
        st.icon,
      ]}
      items={carBrands}
      current="brands"
      step={step}
      handleStep={handleStep}
    />
  )
}

export default ResultBrand
