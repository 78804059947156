import React from 'react'
import { Link, useStaticQuery, graphql } from 'gatsby'
import useWindowSize from '@utils/useWindowSize'
import dataLayerGlobal from '@utils/dataLayerGlobal'
import { Grid, NavList } from '@components'
import * as st from '@assets/styl/Footer.module.styl'
import politicaPdf from '@assets/pdf/politica-de-privacidade.pdf';
import termosPdf from '@assets/pdf/termos-de-uso-wecharge.pdf';


type FooterProps = {
  home?: boolean
}

export default ({ home }: FooterProps) => {
  const config = useStaticQuery(graphql`
    query {
      allConfigurations {
        nodes {
          facebook
          instagram
          linkedin
          youtube
        }
      }
    }
  `).allConfigurations.nodes[0]
  const vs = useWindowSize()
  const dataLayer = dataLayerGlobal();

  const onIosClick = () => {
    dataLayer.push({ event: "GA - Eventos", eventCategory: "Click Download", eventLabel: "IOS" });
  }

  const onAndroidClick = () => {
    dataLayer.push({ event: "GA - Eventos", eventCategory: "Click Download", eventLabel: "Android" });
  }

  return <footer className={st.core}>
    <Grid wide className={st.grid}>

      <div className={st.leftBlock}>
        <div className="icon-logo"></div>
        <NavList home={home} color="white" className={st.navList} />
        {vs.width >= 500 && <><ul className={st.list}>
          <li>&copy; 2023. Wecharge Eletropostos</li>
          <li><Link to="/politica-de-privacidade/" target="_blank">Política de privacidade</Link></li>
          <li><Link to="/termos-de-uso/" target="_blank">Termos de uso</Link></li>
          <li><a href={politicaPdf} target="_blank">Política de privacidade App</a></li>
          <li><a href={termosPdf} target="_blank">Termos de uso App</a></li>
        </ul>
        <a href="https://carbonfreebrasil.com" target="_blank" className={st.carbonFree}></a></>}
      </div>

      <div className={st.rightBlock}>
        <span>Baixe agora o App Wecharge</span>
        <div className={st.apps}>
          <a href="https://play.google.com/store/apps/details?id=br.com.voltbras.wecharge&hl=pt" target="_blank" className={st.android} onClick={onAndroidClick}></a>
          <a href="https://apps.apple.com/br/app/wecharge/id1595933153" target="_blank" className={st.ios} onClick={onIosClick}></a>
        </div>
        <span>Siga nas redes sociais</span>
        <div className={st.socials}>
          <a href={config.facebook} target="_blank" className="icon-facebook"></a>
          <a href={config.instagram} target="_blank" className="icon-instagram"></a>
          <a href={config.linkedin} target="_blank" className="icon-linkedin"></a>
          <a href={config.youtube} target="_blank" className="icon-youtube"></a>
        </div>
      </div>

      {(!vs.width || vs.width < 500) && <>
        <a href="https://carbonfreebrasil.com" target="_blank" className={st.carbonFree}></a>
        <ul className={st.list}>
          <li><Link to="/politica-de-privacidade/" target="_blank">Política de privacidade</Link></li>
          <li><Link to="/termos-de-uso/" target="_blank">Termos de uso</Link></li>
          <li><a href={politicaPdf} target="_blank">Política de privacidade App</a></li>
          <li><a href={termosPdf} target="_blank">Termos de uso App</a></li>
          <li>&copy; 2023. Wecharge Eletropostos</li>
        </ul></>}

      <div className={st.tikiBlock}>
        <a href="https://www.tiki.com.br" target="_blank">Tiki</a>
      </div>

    </Grid>
  </footer>
}
