import React, { useEffect, useState } from 'react'
import requestData from '@api'
import { useStaticQuery, graphql } from 'gatsby'
import useWindowSize from '@utils/useWindowSize'
import { CarbonFreeAnimation, Grid } from '@components'
import * as st from '@assets/styl/CarbonFreeSection.module.styl'
import carbonFreeLogo from '@assets/img/carbon-free-brasil.png'
import { LazyImageFull, ImageState } from 'react-lazy-images'
import hero from '@assets/img/carbon-free-neutralizacao.jpg'
import heroMobile from '@assets/img/carbon-free-neutralizacao-mobile.jpg'

export default () => {
  const query = useStaticQuery(graphql`
    query {
      allNumbers {
        nodes {
          title
          number
        }
      }
    }
  `)
  const [numbers, setNumbers] = useState<Array<{ title: string, number: string }>>(query.allNumbers.nodes.slice(5))
  useEffect(() => {
    requestData('get', 'numbers?max=9999')
      .then(({ result }) => result.slice(1).filter((_, index) => index < 2)
        .forEach(({ title, number }, index) => {
          numbers[index].title = title
          numbers[index].number = number
        })
      )
      .then(_ => setNumbers([...numbers]))
  }, [])
  const vs = useWindowSize()

  return <section className={st.core}>
    <LazyImageFull src={(!vs.width || vs.width < 500) ? heroMobile : hero}>{({ imageState, ref, imageProps: { src } }) =>
      <div ref={ref} className={st.hero} style={{ backgroundImage: `url(${imageState === ImageState.LoadSuccess ? src : ''})` }}>
        <Grid className={st.heroContent}>
          <h3>Nossas recargas são neutralizadas pela Carbon Free Brasil!</h3>
        </Grid>
      </div>
    }</LazyImageFull>
    <Grid className={st.content}>
      <div className={st.text}>
        <span>
          <b>{numbers[0].number}</b>
          {numbers[0].title}
        </span>
        <span>
          <b>{numbers[1].number}</b>
          {numbers[1].title}
        </span>
        <div>
          <p>*Números referentes até o período de Dezembro/2022</p>
          <p><b>A geração de energia não é 100% livre de emissões de carbono.</b> Por isso, somos uma empresa parceira da Carbon Free Brasil: uma iniciativa que, através do plantio de árvores nativas na região, neutraliza nossas emissões de CO₂.</p>
        </div>
      </div>
      {vs.width >= 1190 && <CarbonFreeAnimation />}
      <img src={carbonFreeLogo} alt="Carbon Free Brasil" />
      {(!vs.width || vs.width < 1190) && <CarbonFreeAnimation />}
    </Grid>
  </section>
}