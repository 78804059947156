import { useState, useEffect } from 'react'

export default function dataLayerGlobal() {
  const dataLayerControl = () => {
    if (typeof dataLayer !== 'undefined') {
      /* eslint-disable-next-line */
      return dataLayer;
    }
    return {
      push: () => {},
    };
  };

  return dataLayerControl();
}
