export default [
  { name: "WeCharge", to: "/", scrollTo: "wecharge", scrollHomeOnly: true },
  { name: "Pontos de recarga", to: "/pontos-de-recarga/" },
  { name: "Tempo de recarga", to: "/tempo-de-recarga/" },
  { name: "Segmentos e soluções", to: "/", scrollTo: "solucoes" },
  {
    name: "Loja virtual",
    to: "https://www.wechargeshop.com.br/",
    external: true,
  },
  { name: "Contato", to: "/", scrollTo: "contato" },
];
