// extracted by mini-css-extract-plugin
export var carContainer = "ResultCar-module--car-container--76e56";
export var card = "ResultCar-module--card--b9aa8";
export var iconArrow = "ResultCar-module--icon-arrow--ab8dd";
export var iconCities = "ResultCar-module--icon-cities--cbacf";
export var iconClose = "ResultCar-module--icon-close--d21a4";
export var iconCloseModal = "ResultCar-module--icon-close-modal--d83b2";
export var iconCopy = "ResultCar-module--icon-copy--d2510";
export var iconEnergy = "ResultCar-module--icon-energy--bea18";
export var iconFacebook = "ResultCar-module--icon-facebook--aeb37";
export var iconHamburger = "ResultCar-module--icon-hamburger--56d4b";
export var iconInstagram = "ResultCar-module--icon-instagram--66451";
export var iconLinkedin = "ResultCar-module--icon-linkedin--27104";
export var iconListItem = "ResultCar-module--icon-list-item--1ff6d";
export var iconLogo = "ResultCar-module--icon-logo--aa18e";
export var iconMinus = "ResultCar-module--icon-minus--9f4bc";
export var iconPin = "ResultCar-module--icon-pin--8fdbd";
export var iconPlus = "ResultCar-module--icon-plus--9064c";
export var iconRecharges = "ResultCar-module--icon-recharges--42ca5";
export var iconStations = "ResultCar-module--icon-stations--805e3";
export var iconUsers = "ResultCar-module--icon-users--609a2";
export var iconWallbox = "ResultCar-module--icon-wallbox--2c5ec";
export var iconWhatsapp = "ResultCar-module--icon-whatsapp--7373b";
export var iconYoutube = "ResultCar-module--icon-youtube--3562e";
export var logoContainer = "ResultCar-module--logo-container--adc9e";
export var path1 = "ResultCar-module--path1--22d4b";
export var path10 = "ResultCar-module--path10--e56d9";
export var path11 = "ResultCar-module--path11--34a12";
export var path12 = "ResultCar-module--path12--eed47";
export var path13 = "ResultCar-module--path13--a6206";
export var path14 = "ResultCar-module--path14--70749";
export var path15 = "ResultCar-module--path15--cc3fc";
export var path2 = "ResultCar-module--path2--6dd07";
export var path3 = "ResultCar-module--path3--26dce";
export var path4 = "ResultCar-module--path4--5ae9c";
export var path5 = "ResultCar-module--path5--76f43";
export var path6 = "ResultCar-module--path6--24c43";
export var path7 = "ResultCar-module--path7--89a47";
export var path8 = "ResultCar-module--path8--cf1a3";
export var path9 = "ResultCar-module--path9--a78f7";