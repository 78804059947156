import React, { useRef, useState } from 'react'
import CSS from 'csstype'
import * as st from '@assets/styl/ViewMore.module.styl'
import cn from 'classnames'

type ViewMoreProps = {
  children: any,
  title: string
}

export default ({ children, title }: ViewMoreProps) => {
  const defStyle: CSS.Properties = {}
  const [style, setStyle] = useState(defStyle)
  const content = useRef<HTMLDivElement>()
  const expand = () => {
    if (style.maxHeight) return
    const height = content.current.offsetHeight
    setStyle({ maxHeight: `${height}px` })
    setTimeout(() => setStyle({ maxHeight: 'none' }), 200)
  }

  return <>
    <div className={cn(st.content, !style.maxHeight && st.mask)} style={style}>
      <div ref={content} onClick={({ target }) => {
        if ((target as HTMLElement).className.includes('expand-view-more'))
          expand()
      }}>{children}</div>
    </div>
    <div className={st.btnWrapper}>
      <button className={cn(style.maxHeight && st.hidden)} onClick={expand}>Expandir {title}</button>
    </div>
  </>
}