// extracted by mini-css-extract-plugin
export var card = "ListOfItems-module--card--510b1";
export var icon = "ListOfItems-module--icon--c69c4";
export var iconArrow = "ListOfItems-module--icon-arrow--94fb4";
export var iconCities = "ListOfItems-module--icon-cities--6c74e";
export var iconClose = "ListOfItems-module--icon-close--b5d92";
export var iconCloseModal = "ListOfItems-module--icon-close-modal--e14f3";
export var iconCopy = "ListOfItems-module--icon-copy--61bf0";
export var iconEnergy = "ListOfItems-module--icon-energy--975f6";
export var iconFacebook = "ListOfItems-module--icon-facebook--59ffb";
export var iconHamburger = "ListOfItems-module--icon-hamburger--121b0";
export var iconInstagram = "ListOfItems-module--icon-instagram--142b7";
export var iconLinkedin = "ListOfItems-module--icon-linkedin--14003";
export var iconListItem = "ListOfItems-module--icon-list-item--20abb";
export var iconLogo = "ListOfItems-module--icon-logo--5ed9e";
export var iconMinus = "ListOfItems-module--icon-minus--a61b5";
export var iconPin = "ListOfItems-module--icon-pin--fd793";
export var iconPlus = "ListOfItems-module--icon-plus--c3298";
export var iconRecharges = "ListOfItems-module--icon-recharges--838df";
export var iconStations = "ListOfItems-module--icon-stations--af1e4";
export var iconUsers = "ListOfItems-module--icon-users--c8ea1";
export var iconWallbox = "ListOfItems-module--icon-wallbox--dbc37";
export var iconWhatsapp = "ListOfItems-module--icon-whatsapp--380e1";
export var iconYoutube = "ListOfItems-module--icon-youtube--cfa69";
export var infoContainer = "ListOfItems-module--info-container--05603";
export var logoContainer = "ListOfItems-module--logo-container--439f3";
export var path1 = "ListOfItems-module--path1--37430";
export var path10 = "ListOfItems-module--path10--48a50";
export var path11 = "ListOfItems-module--path11--6bd2a";
export var path12 = "ListOfItems-module--path12--d7609";
export var path13 = "ListOfItems-module--path13--56576";
export var path14 = "ListOfItems-module--path14--5cd31";
export var path15 = "ListOfItems-module--path15--2847b";
export var path2 = "ListOfItems-module--path2--9785e";
export var path3 = "ListOfItems-module--path3--fbbc1";
export var path4 = "ListOfItems-module--path4--9f8d1";
export var path5 = "ListOfItems-module--path5--09f47";
export var path6 = "ListOfItems-module--path6--6411b";
export var path7 = "ListOfItems-module--path7--bfef6";
export var path8 = "ListOfItems-module--path8--9b4d5";
export var path9 = "ListOfItems-module--path9--aaa4d";
export var title = "ListOfItems-module--title--fe8d5";