import React, { useEffect, useRef } from 'react'
import * as st from '@assets/styl/CarbonFreeAnimation.module.styl'

// Manipula a animação dos raios:
export class RayAnimation {
  sprites: Array<SVGGeometryElement>
  track: SVGGeometryElement
  reverse: boolean
  duration: number
  tZero: number
  status: number

  constructor(sprites: Array<SVGGeometryElement>, track: SVGGeometryElement, speed: number, reverse: boolean = false) {
    this.sprites = sprites
    this.track = track
    this.duration = track.getTotalLength() / speed
    this.reverse = reverse
    this.start()
  }

  move(u: number, index: number) {
    const length = u * this.track.getTotalLength()
    const p = this.track.getPointAtLength(this.reverse ? this.track.getTotalLength() - length : length)
    if (u < .1)
      this.sprites[index].setAttribute('transform', `translate(${p.x} ${p.y}) scale(${u / .1})`)
    else if (u > .8)
      this.sprites[index].setAttribute('transform', `translate(${p.x} ${p.y}) scale(${1 - (u - .8) / .2})`)
    else
      this.sprites[index].setAttribute('transform', `translate(${p.x} ${p.y})`)
  }

  start() {
    this.tZero = Date.now()
    requestAnimationFrame(() => this.run())
  }

  run() {
    if (this.status) return
    const u = Math.min((Date.now() - this.tZero) / this.duration, 1)
    this.sprites.map((_, index) => (u + index / this.sprites.length) % 1)
      .forEach((u, index) => this.move(u, index))
    if (u < 1)
      requestAnimationFrame(() => this.run())
    else
      this.start()
  }

  stop() {
    this.status = 1
  }
}

// Manipula a animação dos O2:
class O2Animation extends RayAnimation {
  move(u: number, index: number) {
    const length = u * this.track.getTotalLength() / 2
    const p = this.track.getPointAtLength(this.reverse ? this.track.getTotalLength() - length : length)
    this.sprites[index].setAttribute('transform', `translate(${p.x} ${p.y}) scale(${u})`)
    if (u < .4)
      this.sprites[index].setAttribute('opacity', `${u / .4}`)
    else
      this.sprites[index].setAttribute('opacity', `${1 - (u - .4) / .6}`)
  }
}

export default () => {
  const sprites = Array(9).fill(0).map(() => useRef<SVGGeometryElement>())
  const tracks = Array(4).fill(0).map(() => useRef<SVGGeometryElement>())

  useEffect(() => {
    new RayAnimation([sprites[0].current, sprites[1].current], tracks[0].current, .08, true)
    new RayAnimation([sprites[2].current, sprites[3].current, sprites[4].current], tracks[1].current, .08, true)
    new O2Animation([sprites[5].current, sprites[6].current], tracks[2].current, .14, false)
    new O2Animation([sprites[7].current, sprites[8].current], tracks[3].current, .14, false)
  }, [])

  return <div className={st.core}>

    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 616 282.73">
      <g>
        <g>
          <path d="M447.09,164.61a9.54,9.54,0,0,1-17.62,0s-3.67,5.87-8.81,5.87-8.81-5.87-8.81-5.87-2.56,11.75-11.74,11.75c-6.24,0-8.81-5.87-8.81-5.87a9,9,0,0,1-8.8,5.87,8.52,8.52,0,0,1-8.81-8.23,5.61,5.61,0,0,1,0-.58c0-9,5.87-8.81,5.87-8.81s-8.81-2.57-8.81-11.74,6.24-14.68,11.75-14.68a14,14,0,0,1,8.8,2.94s-.45-8.81,8.81-8.81a9.33,9.33,0,0,1,8.81,5.87s5.14-8.81,14.68-8.81,11.74,11.75,11.74,11.75,12.36-3.56,17.43,1.35c7.71,7.46,2.76,19.2,2.76,19.2s18,0,18,14.68a14.65,14.65,0,0,1-5.87,11.75s11.74,3.47,11.74,14.67c0,12.48-5.14,17.62-11.74,17.62a9.58,9.58,0,0,1-8.81-5.87s-2.75,5.87-11.74,5.87c-7.71,0-11.75-9.91-8.81-17.62,0,0-2.39,5.87-8.81,5.87-6.79,0-8.81-5.78-8.81-8.8" fill="none" stroke="#fff" strokeLinejoin="round" />
          <path d="M373.69,167.55c-4.4,0-13.21,1.65-8.81,15,0,0-21.1,1.47-21.1,17.43s8.17,17.43,12.3,17.43a7.59,7.59,0,0,0,6.05-2.93s-.18,5.87,8.63,5.87c9.36,0,8.81-8.81,8.81-8.81.92,3.85,12.84,5.14,14.68-2.94" fill="none" stroke="#fff" strokeLinejoin="round" />
          <path d="M350.57,216.21s-6.24,5.47-6.24,13S354.42,240,359,238" fill="none" stroke="#fff" strokeLinejoin="round" />
          <path d="M353.14,238c-1.26,2-1.47,14.68,8.81,14.68,8.54,0,11.74-5.87,11.74-5.87s2.93,5.87,14.68,5.87a11.88,11.88,0,0,0,12.33-11.15" fill="none" stroke="#fff" strokeLinejoin="round" />
          <path d="M406,246.55V232.14l-2.93-17.61V199.85L397.18,191V175.88" fill="none" stroke="#fff" strokeLinejoin="round" />
          <path d="M420.66,282.05v-32.3l26.43-35.22L420.66,238l-2.93-5.87,9-29.74-11.94,20.93-5.87-8.8-5.87-14.68,14.68-14.68V170" fill="none" stroke="#fff" strokeLinejoin="round" />
          <path d="M417.73,185.17l14.68-5.88,2.93-8.8" fill="none" stroke="#fff" strokeLinejoin="round" />
          <path d="M434,231.93,453,229.2l9.13-16.44" fill="none" stroke="#fff" strokeLinejoin="round" />
          <path d="M391.3,213.06v19.08L406,246.82" fill="none" stroke="#fff" strokeLinejoin="round" />
          <path d="M370.75,220.4l8.81,11.74H391.3" fill="none" stroke="#fff" strokeLinejoin="round" />
          <path d="M420.66,249.76s11.66,8.35,17.8-2.94a10.54,10.54,0,0,0,7.71,2.94,12,12,0,0,0,12.65-11.41c0-.11,0-.22,0-.34s13.84,2.79,18.72-7.52c4.77-10.09-2.87-18.65-2.87-18.65" fill="none" stroke="#fff" strokeLinejoin="round" />
        </g>
        <path d="M196.34,99.39a10.94,10.94,0,0,1-.51-21.87v0a21.88,21.88,0,0,1,33.64-18.45,31.1,31.1,0,0,1,56.91-5.77,23.89,23.89,0,0,1,31,13.46l0,.05a17.47,17.47,0,1,1,8.74,32.6Z" fill="none" stroke="#fff" />
        <path d="M601.68,132.2a30.24,30.24,0,0,0-54.46-13.51,16.21,16.21,0,0,0-25.81,13.08,15.38,15.38,0,0,0,.16,2.25h-.23a16.29,16.29,0,1,0,0,32.58h76.49a17.41,17.41,0,0,0,3.86-34.4" fill="none" stroke="#fff" />
        <path d="M232.14,265.26a16.79,16.79,0,1,1,16.79,16.79,16.79,16.79,0,0,1-16.79-16.79Zm-138.6,0a16.79,16.79,0,1,1,16.79,16.79,16.79,16.79,0,0,1-16.79-16.79ZM226,272.82l-94.67-.24a1.37,1.37,0,0,1-1.33-1.4v-.05l0-.55c.64-14.56-7.38-23.83-19.46-24.62-10.15-.67-21,8.66-20.22,23.12a3.3,3.3,0,0,1-3,3.52,3.62,3.62,0,0,1-.57,0c-5.29-.65-14.07-1.78-14.72-2.2a3.21,3.21,0,0,1-.7-4c.93-1.22,2.37-3.64.7-15.32-1.63-11.36,3.93-17.19,55.42-24.68,27.26-16.54,27-16.21,34.15-19.11,19.58-7.93,92.1-4.43,105.92,3.31.66.37-.14,1.06-.56,2a3.23,3.23,0,0,0,.84,3.79c5,4.12,9.94,8.7,10.62,10.5,1.14,3,.78,7.51.48,10.85a20,20,0,0,0-.06,2.9,14,14,0,0,0,.66,4c1.55,4.67,3.28,3.9,4.13,6.47,1.65,5,.32,12.66-2.08,15.32-.6.67-6.47,2.44-9.87,3.42a2,2,0,0,1-2.44-1.41,2.07,2.07,0,0,1-.07-.49c-.57-18.88-14.81-22.84-21.46-22-2.4.29-18.6,1.79-18.85,24a2.92,2.92,0,0,1-2.84,2.95ZM147.9,230.2c13.95-.8,39.85-1.59,39.85-1.59s3.54-13.8,4.73-18.36c0-.06-32.93-1.08-47.06,13.89-6.35,6-4.43,6-.59,6h.63C146.23,230.12,147.06,230.13,147.9,230.2Zm52-19.95-2.18,18.36s54.2-1.37,54.2-1.48-7.54-9.15-21.13-14.7c-4.83-2-15.07-2.35-22.43-2.35C203.49,210.08,199.88,210.25,199.88,210.25Z" fill="none" stroke="#fff" strokeMiterlimit="10" />
        <path ref={tracks[0]} d="M108.76,237.36c-24.61,4.77-14,16-36.44,23.7-39.65,6.51-37.72-38.27-37.72-38.27" fill="none" stroke="#fff" strokeLinecap="round" strokeMiterlimit="10" />
        <path d="M118.1,233.16l7.14,1.1v4.26l-4.7,1.16L115.44,238l-5.51,1.34-1.17-3.91Z" fill="none" stroke="#fff" />
        <g>
          <g>
            <rect y="181.5" width="34.6" height="101.23" fill="none" />
            <rect x="0.5" y="182" width="33.6" height="100.23" fill="none" stroke="#fff" />
          </g>
          <g>
            <rect x="5.13" y="187.9" width="25.63" height="52.54" rx="3" fill="none" />
            <rect x="5.63" y="188.4" width="24.63" height="51.54" rx="2.5" fill="none" stroke="#fff" />
          </g>
          <path d="M10.89,215.16h4.79l-2.57,8.3L24.22,212.3H19.86l2-8.14Z" fill="none" stroke="#fff" />
        </g>
        <path ref={tracks[1]} d="M405.92,246.39s2.49,38.94-23.82,23.72-35.75,11.4-57.71-4.9-40.07-10.12-40.07-10.12" fill="none" stroke="#fff" />
      </g>

      <path ref={tracks[2]} d="M439.82,150.27l-.59-.81a119.25,119.25,0,0,0,25.21-24.33,123.45,123.45,0,0,0,20.49-41,138.14,138.14,0,0,0,5.77-46.92l1,0a139.14,139.14,0,0,1-5.81,47.26,124.54,124.54,0,0,1-20.65,41.3A120.72,120.72,0,0,1,439.82,150.27Z" fill="none" />
      <path ref={tracks[3]} d="M417.73,144.72c3.15-.49,1.51-33.32-4.32-60.43C406.87,53.87,393.17,22,390,22.91,384,24.7,411.6,145.69,417.73,144.72Z" fill="none" />

      {sprites.slice(0, 5).map((ref, key) => <g key={key} ref={ref}>
        <path d="M0,9.906H4.312L2,17.376,12,7.329H8.077L9.862,0Z" transform="translate(-6.0083 -8.69165)" fill="#fff" />
      </g>)}

      {sprites.slice(5).map((ref, key) => <g key={key} ref={ref}>
        <path d="M477.32,68.84c16.84,0,28.88-12.34,28.88-28.78s-12-28.9-28.88-28.9-28.88,12.46-28.88,28.9S460.48,68.84,477.32,68.84Zm0-2.58c-15.28,0-26.22-11.32-26.22-26.2S462,13.74,477.32,13.74,503.54,25,503.54,40.06,492.6,66.26,477.32,66.26Zm37.77-26.67c14.1-14.07,15.79-17.15,15.79-21.86,0-4.9-3.53-8.25-8.67-8.25s-8.89,3.31-8.89,8.26v.85h1.46v-.85c0-4,3-6.85,7.43-6.85s7.2,2.75,7.2,6.84c0,4.51-1.64,7.31-16.93,22.45V41h20.08V39.59Z" transform="translate(-490.30865 -39.14165)" fill="#7cbb53" />
      </g>)}
    </svg>

  </div>
}