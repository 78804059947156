import React, { ChangeEvent, useState, useEffect } from 'react'
import { Button } from '@components'
import cn from 'classnames'
import { navigate } from 'gatsby'
import searchIcon from '@assets/svg/search-recharge.svg'
import * as st from '@assets/styl/RechargeTime/Search.module.styl'

const Search = ({
  setLoading,
  marca,
  modelo,
  query,
  step,
  setQuery,
  setError: setErrorPage,
}) => {
  const urlPage = '/tempo-de-recarga'
  const [error, setError] = useState('')
  const [searchData, setSearchData] = useState({
    search: '',
    modelo: '',
    marca: '',
  })

  const handleChange = async (e) => {
    setErrorPage('')
    setError('')

    let updateForm = { ...searchData }
    updateForm[e.target.name] = e.target.value

    if (e.target.value === '' && step === 1) {
      setLoading(false)
      navigate(urlPage)
    } else if (e.target.value.length < query.length && step === 2) {
      navigate(`${urlPage}?marca=${updateForm.marca}`)
    }

    // if (e.target.value === '' && step === 1) {
    //   setLoading(false)
    //   navigate(urlPage)
    // }

    // if (e.target.value !== query && step === 2) {
    //   navigate(`${urlPage}?marca=${marca}`)
    // }

    setSearchData(updateForm)
  }

  const handleSubmit = (e: ChangeEvent<HTMLFormElement>) => {
    e.preventDefault()
    let url = []

    const brandLoweCase = searchData.search.replace(/\s/g, '').toLowerCase()

    if (brandLoweCase === '') {
      setError('Preencha o campo!')
    } else {
      setError('')

      if (searchData.search !== '') {
        url.push(`busca=${searchData.search}`)
      }
      modelo ? url.push(`&modelo=${searchData.modelo}`) : null
      marca ? url.push(`&marca=${searchData.marca}`) : null
      let completeURL = ''

      if (step === 1) {
        completeURL = url.length > 0 ? `${urlPage}?${url.join('')}` : urlPage
      }

      if (step === 2 && window.location.pathname.endsWith('/')) {
        completeURL = url.length > 0 ? `${urlPage}/?${url.join('')}` : urlPage
      } else {
        completeURL = url.length > 0 ? `${urlPage}?${url.join('')}` : urlPage
      }

      if (completeURL !== urlPage) {
        setLoading(true)
        navigate(completeURL)
      }
    }
  }

  useEffect(() => {
    let updateForm = { ...searchData }
    updateForm.search = query ? query : ''
    updateForm.modelo = modelo ? modelo : ''
    updateForm.marca = marca ? marca : ''

    setSearchData(updateForm)
  }, [query, modelo, marca])

  return (
    <form
      className={cn('flex-center', st.containerSearch)}
      onSubmit={handleSubmit}
    >
      <div className={st.container}>
        <img
          src={searchIcon}
          className={st.iconSearch}
          alt="Icone de busca"
          width={23}
          height={23}
        />
        <input
          value={searchData.search}
          onChange={(e) => handleChange(e)}
          className={st.search}
          type="text"
          name="search"
          placeholder={
            step === 1 ? 'Busque por marca ou modelo' : 'Busque por modelo'
          }
        />
        {error && <p className={st.error}>{error}</p>}
      </div>
      <Button className={st.buttonSearch}>Buscar</Button>
    </form>
  )
}

export default Search
