import product1 from '@assets/img/tempo-de-recarga/store/product-1.jpg'
import product2 from '@assets/img/tempo-de-recarga/store/product-2.jpg'
import product3 from '@assets/img/tempo-de-recarga/store/product-3.jpg'

interface productListProps {
  id: number
  description: string
  image: string
  link: string
}

const productList: Array<productListProps> = [
  {
    id: 1,
    description: 'Carregador para veículo elétrico Wallbox Pulsar Plus 22 kW',
    link: 'https://www.wechargeshop.com.br/wallboxes',
    image: product1,
  },
  {
    id: 2,
    description: 'Carregador para veículo elétrico Wallbox Commander 2S - 22kW',
    link: 'https://www.wechargeshop.com.br/wallboxes',
    image: product2,
  },
  {
    id: 3,
    description:
      'Carregador para veículo elétrico Wallbox Commander 2 - 22kW (Display)',
    link: 'https://www.wechargeshop.com.br/wallboxes',
    image: product3,
  },
  {
    id: 4,
    description:
      'Carregador para veículo elétrico Wallbox Commander 2 - 22kW (Display)',
    link: 'https://www.wechargeshop.com.br/wallboxes',
    image: product3,
  },
]

export default productList
