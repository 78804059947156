import React, { useEffect, useState } from 'react'
import * as st from '@assets/styl/RechargeTime/ShowVehicleMake.module.styl'
import { LazyImageFull, ImageState } from 'react-lazy-images'
import api from '@api'

const ShowVehicleMake = ({ brand }) => {
  const [brandFiltered, setBrandFiltered] = useState([])

  const t = () => {
    if (brand.includes('-') && brand === 'swap-e') {
      return brand
    } else if (brand.includes('-')) {
        return brand.replace('-', ' ')
      } else {
        return brand
      }
  }

  const fetchData = async () => {
    const data = await api('get', `charge?name=${t()}`)
    setBrandFiltered(data.result)
  }
  
  useEffect(() => {
    fetchData()
  }, [brand])
  
  const typeBrand = brandFiltered.find((item) => {
    return item.type === "brand"
  })

  return (
    <>
      {typeBrand && (
        <div className={st.brandContainer}>
          <div className={st.imageContainer}>
            <LazyImageFull src={typeBrand.image} alt={`Icone da ${typeBrand.name.toLowerCase()}`}>
              {({ imageState, ref, imageProps: { src } }) =>
                <div ref={ref}>
                  <img src={imageState === ImageState.LoadSuccess ? src : ''} />
                </div>
            }</LazyImageFull>
          </div>
          <div className={st.infoContainer}>
            <p className={st.title}>Marca do veículo</p>
            <span className={st.brand}>{typeBrand.name.toLowerCase()}</span>
          </div>
        </div>
      )}
    </>
  )
}

export default ShowVehicleMake
