import React, { Dispatch, useEffect } from 'react'
import * as st from '@assets/styl/RechargeTime/CalculationSteps.module.styl'
import cn from 'classnames'
import { Link, navigate } from 'gatsby'

interface PropsCalculationSteps {
  step: number
  marca: string
  setStep: Dispatch<React.SetStateAction<number>>
  setQuery: Dispatch<React.SetStateAction<string>>
  setMarca: Dispatch<React.SetStateAction<string>>
}

const CalculationSteps = ({ step, setStep, setQuery, setMarca, marca }: PropsCalculationSteps) => {
  const urlPage = '/tempo-de-recarga/'

  const beforeStep = () => {
    setQuery('')
    setStep((previusState) => previusState - 1)
  }
  
  const handleStep = (number: number) => {
    if (number === 1 && step >= 2) beforeStep()
    if (number === 1 && step === 3) beforeStep()
    if (number === 2 && step === 3) beforeStep()
  }

  // useEffect(() => {
  //   let completeURL = ''

  //   if (window.location.pathname.endsWith('/')) {
  //     completeURL = `/tempo-de-recarga${window.location.search}`

  //     navigate(completeURL)
  //   }
  //     // completeURL = url.length > 0 ? `${urlPage}?${url.join('')}` : urlPage
  //   // }
  // }, [])

return (
  <ul className={cn(st.containerSteps, 'flex-center')}>
      <li className={st.step}
      onClick={() => {
        setQuery('')
        setMarca('')
        
        if (step === 2) {
          setStep((previusState) => previusState - 1)
        } else if (step === 3) {
          setStep((previusState) => previusState - 2)
        }
      }}>
        <Link to={step !== 1 ? urlPage : ''}>
          <span className={cn(st.icon, 'flex-center', { [st.activeStep]: step >= 1 })}>1</span>
          <p className={st.title}>Selecione a marca</p>
        </Link>
      </li>
      <li className={st.step} onClick={() => handleStep(2)}>
        <Link to={step === 3 && `${urlPage}?marca=${marca}`}>
          <span className={cn(st.icon, 'flex-center', { [st.activeStep]: step >= 2 })}>2</span>
          <p className={st.title}>Selecione o modelo</p>
        </Link>
      </li>
      <li className={st.step}>
        <Link to={step === 4 && ''}>
          <span className={cn(st.icon, 'flex-center', { [st.activeStep]: step === 3 })}>3</span>
          <p className={st.title}>Tempo de recarga</p>
        </Link>
      </li>
    </ul>
  )
}

export default CalculationSteps
