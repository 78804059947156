import React from 'react'
import cn from 'classnames'
import * as st from '@assets/styl/RechargeTime/ListOfItems.module.styl'
import { LazyImageFull, ImageState } from 'react-lazy-images'
import { Link } from 'gatsby'

interface ListOfItemsProps {
  classNames: Array<string>
  items: itemsProps[]
  current: 'brands' | 'cars'
  handleStep: any
  step: number
}

type itemsProps = {
  image: string
  id: number
  name: string
  slug: string
  type?: string
}

const ListOfItems = ({
  classNames,
  items,
  current,
  handleStep,
  step
}: ListOfItemsProps) => {
  const urlPage = '/tempo-de-recarga'

  return (
    <ul className={cn('flex-center', classNames[0])}>
      {items?.map(({ id, name, image, type, slug }) => {
        const generateLink = () => {
          if (type === 'car') {
            return `${urlPage}?modelo=${slug}`
          }
          
          if (step === 1 && type === 'brand') {
            return `${urlPage}?marca=${slug}`
          }
        }

        return (
          <li className={cn(st.card, classNames[1])} key={id} onClick={() => handleStep(id, type, name)}>
            <Link to={generateLink()}>
              <div className={cn(st.logoContainer, classNames[2])}>
                <LazyImageFull src={image} alt={`${current === 'brands' ? 'Logo da' : 'Imagem do carro'} ${name}`}>
                  {({ imageState, ref, imageProps: { src } }) =>
                    <div ref={ref}>
                      <img src={imageState === ImageState.LoadSuccess ? src : ''} width={current === 'cars' && 90} />
                    </div>
                }</LazyImageFull>
              </div>
              <div className={cn(st.infoContainer, classNames[3])}>
                <span className={cn(st.title, classNames[4])}>{name}</span>
                <span className={cn('icon-arrow', st.icon, classNames[5])}></span>
              </div>
            </Link>
          </li>
        )
      }
      )}
    </ul>
  )
}

export default ListOfItems
