// extracted by mini-css-extract-plugin
export var carContainer = "ResultBrand-module--car-container--3b634";
export var card = "ResultBrand-module--card--612c0";
export var iconArrow = "ResultBrand-module--icon-arrow--b567e";
export var iconCities = "ResultBrand-module--icon-cities--9ac6b";
export var iconClose = "ResultBrand-module--icon-close--c59ef";
export var iconCloseModal = "ResultBrand-module--icon-close-modal--12670";
export var iconCopy = "ResultBrand-module--icon-copy--e2666";
export var iconEnergy = "ResultBrand-module--icon-energy--025e4";
export var iconFacebook = "ResultBrand-module--icon-facebook--725ec";
export var iconHamburger = "ResultBrand-module--icon-hamburger--a856d";
export var iconInstagram = "ResultBrand-module--icon-instagram--a5721";
export var iconLinkedin = "ResultBrand-module--icon-linkedin--1f4ca";
export var iconListItem = "ResultBrand-module--icon-list-item--c15a9";
export var iconLogo = "ResultBrand-module--icon-logo--270db";
export var iconMinus = "ResultBrand-module--icon-minus--5d867";
export var iconPin = "ResultBrand-module--icon-pin--551e2";
export var iconPlus = "ResultBrand-module--icon-plus--b83d4";
export var iconRecharges = "ResultBrand-module--icon-recharges--748ae";
export var iconStations = "ResultBrand-module--icon-stations--cc3c8";
export var iconUsers = "ResultBrand-module--icon-users--406ea";
export var iconWallbox = "ResultBrand-module--icon-wallbox--81af7";
export var iconWhatsapp = "ResultBrand-module--icon-whatsapp--a597a";
export var iconYoutube = "ResultBrand-module--icon-youtube--72338";
export var infoContainer = "ResultBrand-module--info-container--c6240";
export var logoContainer = "ResultBrand-module--logo-container--fc158";
export var path1 = "ResultBrand-module--path1--ee745";
export var path10 = "ResultBrand-module--path10--19c5b";
export var path11 = "ResultBrand-module--path11--7ad8c";
export var path12 = "ResultBrand-module--path12--40f67";
export var path13 = "ResultBrand-module--path13--13b21";
export var path14 = "ResultBrand-module--path14--330a8";
export var path15 = "ResultBrand-module--path15--e9345";
export var path2 = "ResultBrand-module--path2--7f0b4";
export var path3 = "ResultBrand-module--path3--3a9d1";
export var path4 = "ResultBrand-module--path4--5e6f2";
export var path5 = "ResultBrand-module--path5--76a08";
export var path6 = "ResultBrand-module--path6--59ba4";
export var path7 = "ResultBrand-module--path7--506cf";
export var path8 = "ResultBrand-module--path8--6846c";
export var path9 = "ResultBrand-module--path9--c09de";
export var title = "ResultBrand-module--title--07ca4";