// extracted by mini-css-extract-plugin
export var carInformation = "CarInformation-module--car-information--713ee";
export var carLogo = "CarInformation-module--car-logo--56737";
export var carName = "CarInformation-module--car-name--7100e";
export var carPicture = "CarInformation-module--car-picture--b5230";
export var carTitle = "CarInformation-module--car-title--658e9";
export var container = "CarInformation-module--container--c632d";
export var defaultSection = "CarInformation-module--default-section--0c575";
export var edit = "CarInformation-module--edit--e6451";
export var editButton = "CarInformation-module--edit-button--53bbd";
export var fontSize = "CarInformation-module--font-size--9bf45";
export var iconArrow = "CarInformation-module--icon-arrow--8a252";
export var iconCities = "CarInformation-module--icon-cities--412fc";
export var iconClose = "CarInformation-module--icon-close--30de3";
export var iconCloseModal = "CarInformation-module--icon-close-modal--a3c83";
export var iconCopy = "CarInformation-module--icon-copy--3daa5";
export var iconEnergy = "CarInformation-module--icon-energy--de449";
export var iconFacebook = "CarInformation-module--icon-facebook--ec09f";
export var iconHamburger = "CarInformation-module--icon-hamburger--6d5dc";
export var iconInstagram = "CarInformation-module--icon-instagram--b05c5";
export var iconLinkedin = "CarInformation-module--icon-linkedin--62728";
export var iconListItem = "CarInformation-module--icon-list-item--af4fd";
export var iconLogo = "CarInformation-module--icon-logo--dc986";
export var iconMinus = "CarInformation-module--icon-minus--cd8a7";
export var iconPin = "CarInformation-module--icon-pin--c2b48";
export var iconPlus = "CarInformation-module--icon-plus--712e1";
export var iconRecharges = "CarInformation-module--icon-recharges--c9c8c";
export var iconStations = "CarInformation-module--icon-stations--ef3de";
export var iconUsers = "CarInformation-module--icon-users--b2688";
export var iconWallbox = "CarInformation-module--icon-wallbox--9ff81";
export var iconWhatsapp = "CarInformation-module--icon-whatsapp--1cc28";
export var iconYoutube = "CarInformation-module--icon-youtube--7ec20";
export var image = "CarInformation-module--image--4fd29";
export var labels = "CarInformation-module--labels--098ec";
export var listContainer = "CarInformation-module--list-container--27627";
export var path1 = "CarInformation-module--path1--5f827";
export var path10 = "CarInformation-module--path10--08805";
export var path11 = "CarInformation-module--path11--8cd2f";
export var path12 = "CarInformation-module--path12--12124";
export var path13 = "CarInformation-module--path13--3ab58";
export var path14 = "CarInformation-module--path14--8a351";
export var path15 = "CarInformation-module--path15--13059";
export var path2 = "CarInformation-module--path2--cb1e0";
export var path3 = "CarInformation-module--path3--70580";
export var path4 = "CarInformation-module--path4--98af8";
export var path5 = "CarInformation-module--path5--6097d";
export var path6 = "CarInformation-module--path6--81f7c";
export var path7 = "CarInformation-module--path7--b880f";
export var path8 = "CarInformation-module--path8--b8f7a";
export var path9 = "CarInformation-module--path9--c4a8d";
export var rechargeInformation = "CarInformation-module--recharge-information--e9910";
export var resultInfoCarSection = "CarInformation-module--result-info-car-section--8cf0c";