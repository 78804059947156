import React, { useEffect, useState } from 'react'
import requestData from '@api'
import { graphql, useStaticQuery } from 'gatsby'
import { DirectionsRenderer, DirectionsRendererProps, GoogleMap, Marker, withGoogleMap, withScriptjs } from 'react-google-maps'
import { gMapsConfig } from '@data'
import { Button, Modal, SearchInput } from '@components'
import cn from 'classnames'
import * as st from '@assets/styl/Map.module.styl'
import userLocationPin from '@assets/svg/user-location.svg'

import pin1 from '@assets/svg/pin-verde.svg'
import pin2 from '@assets/svg/pin-azul.svg'
import pin3 from '@assets/svg/pin-laranja.svg'
import pin4 from '@assets/svg/pin-cinza.svg'

function pin(status: string) {
  status = status.toUpperCase()
  if (status === 'AVAILABLE')
    return pin1
  else if (status === 'CHARGING')
    return pin2
  else if (status === 'UNAVAILABLE')
    return pin3
  return pin4
}

function translate(status: string) {
  status = status.toUpperCase()
  if (status === 'AVAILABLE')
    return 'Disponível'
  else if (status === 'CHARGING')
    return 'Ocupado'
  else if (status === 'UNAVAILABLE')
    return 'Inativo'
  else if (status === 'OFFLINE')
    return 'Desligado'
  return 'Desconhecido'
}

function printDistance(distance: number) {
  return `${Math.round(distance / 100) / 10} km`
}

type Station = {
  name: string,
  status: string,
  openingHours: string
  coordinates: {
    latitude: number,
    longitude: number
  },
  address: {
    city: string,
    neighborhood: string,
    street: string,
    streetNumber: string
  }
}

type MapProps = {
  location?: { lat: number, lng: number },
  setLocation?: React.Dispatch<React.SetStateAction<{ lat: number, lng: number }>>
}

const Map = withScriptjs(withGoogleMap(({ location, stations = [], directions, openModal }: {
  location?: { lat: number, lng: number },
  stations: Array<Station>,
  directions?: DirectionsRendererProps,
  openModal: (s: Station) => void
}) =>
  <GoogleMap
    defaultZoom={11}
    center={location || { lat: -27.62, lng: -48.613 }}
    options={{ fullscreenControl: false, zoomControl: false, mapTypeControl: false, streetViewControl: false }}
  >
    {location && <Marker position={location} icon={{ url: userLocationPin, anchor: new google.maps.Point(25, 25), size: new google.maps.Size(50, 50) }} cursor="auto" />}
    {stations.filter(s => s.coordinates).map(({ name, status, openingHours, coordinates: { latitude: lat, longitude: lng }, address }, key) =>
      <Marker
        key={key}
        position={{ lat, lng }}
        icon={{
          url: pin(status),
          anchor: new google.maps.Point(15.95, 39.8),
          size: new google.maps.Size(31.9, 42.95)
        }}
        cursor="pointer"
        onClick={() => openModal({ name, status, openingHours, coordinates: { latitude: lat, longitude: lng }, address })}
      />
    )}
    {directions && <DirectionsRenderer directions={directions} />}
  </GoogleMap>
))

export default ({ location, setLocation }: MapProps) => {
  const [stations, setStations] = useState<Array<Station>>(
    useStaticQuery(graphql`
      query {
        allStations {
          nodes {
            cpoId
            name
            openingHours
            status
            coordinates {
              latitude
              longitude
            }
            address {
              city
              neighborhood
              street
              streetNumber
            }
          }
        }
      }
    `).allStations.nodes
  )

  const [directions, setDirections] = useState<DirectionsRendererProps>()

  useEffect(() => {
    if ('geolocation' in navigator)
      navigator.geolocation.getCurrentPosition(({ coords: { latitude: lat, longitude: lng } }) => {
        const waitGoogle = () => {
          if (typeof google === 'undefined') setTimeout(() => waitGoogle(), 100)
          else setLocation({ lat, lng })
        }
        waitGoogle()
      })
    const _stations = []
    requestData('get', 'maps/stations?max=9999')
      .then(({ result }) => result
        .forEach(station => _stations.push(station))
      )
      .then(_ => setStations(_stations))
  }, [])

  function calcDistance(lat: number, lng: number) {
    return google.maps.geometry.spherical.computeDistanceBetween({ lat, lng }, location)
  }

  const [modalInfo, setModalInfo] = useState<Station & { open: boolean }>()

  const MapItem = ({ name, status, openingHours, coordinates, address }: Station) => <>
    <div className="icon-pin">
      <span className="path1"></span>
      <span className="path2"></span>
      <span></span>
    </div>
    <h4>{name}</h4>
    <p className={st.address}>
      {address.street}, {address.streetNumber} - {address.neighborhood} - {address.city}
      <button className="icon-copy" onClick={() => {
        const tempInput: HTMLInputElement = document.createElement('input')
        tempInput.setAttribute('style', 'position: absolute; left: -1000px; top: -1000px')
        tempInput.value = `${address.street}, ${address.streetNumber} - ${address.neighborhood} - ${address.city}`
        document.body.appendChild(tempInput)
        tempInput.select()
        document.execCommand('copy')
        document.body.removeChild(tempInput)
      }}></button>
    </p>
    <p>
      Local: <b>{translate(status)}</b><br/>
      {openingHours && <>Horário de funcionamento: {openingHours}</>}
    </p>
    {location && <p>
      Distância até o local: <b>{printDistance(calcDistance(coordinates.latitude, coordinates.longitude))}</b>
    </p>}
    {location && <Button className={st.btn} onClick={() => {
      const directionsService = new google.maps.DirectionsService()
      directionsService.route({
          origin: new google.maps.LatLng(location.lat, location.lng),
          destination: new google.maps.LatLng(coordinates.latitude, coordinates.longitude),
          travelMode: google.maps.TravelMode.DRIVING
        },
        result => {
          if (result.status === google.maps.DirectionsStatus.OK) {
            setDirections(result)
            modalInfo && setModalInfo({ ...modalInfo, open: false })
            setExpanded(false)
          }
        })
    }}>Traçar rota</Button>}
  </>

  const [expanded, setExpanded] = useState(false)

  return <>
    <section className={st.core} id="mapa">
      <Map
        googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${gMapsConfig.key}&v=3.exp&libraries=geometry,drawing,places`}
        loadingElement={<></>}
        containerElement={<div className={st.map} />}
        mapElement={<div className={st.map} style={{ height: '100%' }} />}
        location={location}
        stations={stations}
        directions={directions}
        openModal={(s) => setModalInfo({ open: true, ...s })}
      />
      <div className={st.search}>
        <h2>Confira aqui o eletroposto mais perto de você.</h2>
        <SearchInput setLocation={setLocation} className={st.input} />
        {location && <>
          <p className={cn(st.resultCount, expanded && st.expanded)} onClick={() => setExpanded(!expanded)}>Resultado da busca: <b>{stations.filter(s => s.coordinates).filter(s => calcDistance(s.coordinates.latitude, s.coordinates.longitude) <= 100000).length} resultados</b></p>
          <ul className={cn(st.locationDescriptions, expanded && st.expanded)}>
            {stations.filter(s => s.coordinates).filter(s => calcDistance(s.coordinates.latitude, s.coordinates.longitude) <= 100000).map((station, key) =>
              <li key={key} className={cn(st.locationDescription, st[station.status.toLowerCase()])}>
                {MapItem(station)}
              </li>
            )}
          </ul>
        </>}
      </div>
      <ul className={st.legend}>
        <li><img src={pin1} />Disponível</li>
        <li><img src={pin2} />Ocupado</li>
        <li><img src={pin3} />Inativo</li>
        <li><img src={pin4} />Desligado</li>
      </ul>
    </section>

    {modalInfo && <Modal open={modalInfo.open} close={() => setModalInfo({ ...modalInfo, open: false })}>
      <div className={cn(st.locationDescription, st[modalInfo.status.toLowerCase()])}>
        {MapItem(modalInfo)}
      </div>
    </Modal>}
  </>
}