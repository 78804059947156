import React, { useEffect, useState } from 'react'
import { scroller } from 'react-scroll'
import Geocode from 'react-geocode'
import { Autocomplete } from '@mui/material'
import { gMapsConfig } from '@data'
import * as st from '@assets/styl/SearchInput.module.styl'
import cn from 'classnames'

Geocode.setApiKey(gMapsConfig.key)
Geocode.setLanguage(gMapsConfig.language)
Geocode.setRegion(gMapsConfig.region)

type SearchInputProps = {
  setLocation: React.Dispatch<React.SetStateAction<{ lat: number, lng: number }>>,
  className?: string,
  scrollToMap?: boolean
}

export default ({ setLocation, scrollToMap, className }: SearchInputProps) => {
  const [query, setQuery] = useState('')
  const [options, setOptions] = useState([])

  useEffect(() => {
    if (!query) {
      setOptions([])
      return
    }
    Geocode.fromAddress(query)
      .then(res => res.results.map(({ formatted_address: label, geometry: { location: value } }) => ({ label, value })))
      .then(res => setOptions(res))
      .catch(_ => {})
  }, [query])

  return <Autocomplete
    options={options}
    onChange={(_, { label, value }) => {
      setQuery(label)
      setLocation(value)
      scrollToMap && scroller.scrollTo('mapa', { smooth: true, duration: 500 })
    }}
    freeSolo
    renderInput={({ inputProps, InputProps: { ref } }) =>
      <div ref={ref} className={st.inputWrapper}>
        <input {...inputProps} value={query} onChange={(e) => setQuery(e.target.value)} className={cn('autocomplete', className)} type="text" placeholder="Busque por rua, cidade ou CEP" />
      </div>
    }
  />
}