import React from 'react'
import { Link } from 'gatsby'
import { Link as ScrollLink } from 'react-scroll'
import * as st from '@assets/styl/Button.module.styl'
import cn from 'classnames'

type ButtonProps = {
  children: any,
  to?: string,
  scroll?: boolean,
  external?: boolean,
  onClick?: () => void,
  color?: string,
  className?: string
}

export default ({ children, to, scroll, external, onClick, color = 'blue', className }: ButtonProps) =>
  scroll ?
    <ScrollLink smooth duration={500}
      to={to}
      className={cn(st.core, className, color && st[color])}
      onClick={onClick}
    >{children}</ScrollLink>
  : to ? (
    external ?
      <a
        href={to}
        target="_blank"
        className={cn(st.core, className, color && st[color])}
        onClick={onClick}
      >{children}</a>
    :
      <Link
        to={to}
        className={cn(st.core, className, color && st[color])}
        onClick={onClick}
      >{children}</Link>
  ) :
    <button
      className={cn(st.core, className, color && st[color])}
      onClick={onClick}
    >{children}</button>