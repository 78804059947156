import React from 'react'
import useWindowSize from '@utils/useWindowSize'
import { Grid } from '@components'
import * as st from '@assets/styl/WallboxSection.module.styl'
import { LazyImageFull, ImageState } from 'react-lazy-images'
import hero from '@assets/img/tecnologia-wallbox.jpg'
import heroMobile from '@assets/img/tecnologia-wallbox-mobile.jpg'

export default () => {
  const vs = useWindowSize()

  return <section>
    <LazyImageFull src={(!vs.width || vs.width < 500) ? heroMobile : hero}>{({ imageState, ref, imageProps: { src } }) =>
      <div ref={ref} className={st.hero} style={{ backgroundImage: `url(${imageState === ImageState.LoadSuccess ? src : ''})` }}>
        <Grid className={st.heroContent}>
          <h3>Tecnologia de carregamento Wallbox&reg;</h3>
        </Grid>
        <span className="icon-wallbox"></span>
      </div>
    }</LazyImageFull>
    <Grid className={st.content}>
      <div className={st.images}></div>
      <ul>
        <li><span className="icon-list-item"></span>A empresa espanhola WallBox é uma das maiores empresas de carregadores elétricos do mundo, presente na Europa e nos EUA;</li>
        <li><span className="icon-list-item"></span>Load Balance integrado, OCPP, comunicação entre os carregadores;</li>
        <li><span className="icon-list-item"></span>Compatibilidade com diversas marcas de carros elétricos e híbridos;</li>
        <li><span className="icon-list-item"></span>Plug Tipo 2, mais utilizado e comum no Brasil.</li>
      </ul>
    </Grid>
  </section>
}