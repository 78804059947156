import React from 'react'
import { Link } from 'gatsby'
import { Link as ScrollLink, scroller } from 'react-scroll'
import { menuItems } from '@data'
import * as st from '@assets/styl/Navlist.module.styl'
import cn from 'classnames'
import windowGlobal from '@utils/windowGlobal'

type NavListProps = {
  home?: boolean
  color?: string
  onClose?: () => void
  className?: string
}

export default ({ home, color, onClose, className }: NavListProps) => {
  const uau = [
    '/404',
    '/ajuda',
    '/',
    '/politica-de-privacidade',
    '/pontos-de-recarga',
    '/sucesso',
    '/tempo-de-recarga',
    '/termos-de-uso',
  ]

  const isPartner = windowGlobal.location?.pathname.includes('ajuda/')

  return (
    <nav
      className={cn(st.core, color && st[color], className)}
      itemScope
      itemType="http://www.schema.org/SiteNavigationElement"
    >
      {onClose && <button className="icon-close" onClick={onClose}></button>}
      <ul>
        {menuItems.map(
          ({ name, to, external, scrollTo, scrollHomeOnly }, key) => {
            if (isPartner && name === 'Loja virtual') return
            return (
              <li key={key}>
                {home && scrollTo ? (
                  <ScrollLink
                    to={scrollTo}
                    smooth="linear"
                    duration={500}
                    onClick={() => {
                      onClose && onClose()
                      setTimeout(() => scroller.scrollTo(scrollTo), 600)
                    }}
                  >
                    {name}
                    <span className="icon-arrow"></span>
                  </ScrollLink>
                ) : external ? (
                  <a
                    href={to}
                    target="_blank"
                    onClick={() => {
                      !scrollHomeOnly &&
                        setTimeout(() => scroller.scrollTo(scrollTo), 300)
                      onClose && onClose()
                    }}
                  >
                    {name}
                    <span className="icon-arrow"></span>
                  </a>
                ) : (
                  <Link
                    to={to}
                    onClick={() => {
                      !scrollHomeOnly &&
                        setTimeout(() => scroller.scrollTo(scrollTo), 300)
                      onClose && onClose()
                    }}
                  >
                    {name}
                    <span className="icon-arrow"></span>
                  </Link>
                )}
              </li>
            )
          }
        )}
      </ul>
    </nav>
  )
}
