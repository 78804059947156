import React from 'react'
import * as st from '@assets/styl/Loading.module.styl'

interface LoadingProps {
  size?: number
  color?: string
}

const Loading = ({ size, color }: LoadingProps) => {
  const spinnerStyle = {
    width: size,
    height: size,
    borderTopColor: color,
  }

  return <div className={st.loading} style={spinnerStyle}></div>
}

export default Loading
