import React, { Dispatch, useEffect, useState } from 'react'
import { ListOfItems } from '@components'
import { navigate } from 'gatsby'
import * as st from '@assets/styl/RechargeTime/ResultCar.module.styl'

interface ResultCarProps {
  step: number
  modelo: string
  setStep: Dispatch<React.SetStateAction<number>>
  setModelo: Dispatch<React.SetStateAction<string>>
  setLoading: Dispatch<React.SetStateAction<boolean>>
  brands: Array<{
    id: number
    name: string
    image: string
    slug: string
    type: string
  }>
}

const ResultCar = ({ step, setStep, brands, modelo, setModelo, setLoading}: ResultCarProps) => {
  const urlPage = '/tempo-de-recarga'
  const params = new URLSearchParams(window.location.search)
  const marca = params.get('marca')
  const busca = params.get('busca')
  const [carsFiltered, setCarsFiltered] = useState([])

  const handleStep = (id: number) => {
    const carFiltered = brands.find((car) => {
      return car.id === id
    })

    setModelo(carFiltered.slug)

    if (marca !== null) {
      let completeURL = ''
      params.set('modelo', carFiltered.slug.toLowerCase())

      if (busca !== null) {
        completeURL = `${urlPage}?marca=${marca}&modelo=${carFiltered.slug.toLowerCase()}`
      } else {
        completeURL = `${urlPage}?${params.toString()}`
      }

      if (completeURL !== urlPage) {
        setLoading(true)
        navigate(completeURL)
      }
    }
  }
  
  useEffect(() => {
    if (modelo !== '') {
      setStep((previusState: number) => previusState + 1)
    }

    if (modelo === '') {
      setCarsFiltered(brands)
    }
  }, [brands, window.location.search])
  
  return (
    <ListOfItems
      classNames={[
        st.carContainer,
        st.card,
        st.logoContainer,
        st.infoContainer,
        st.title,
        st.icon,
      ]}
      items={carsFiltered}
      current="cars"
      step={step}
      handleStep={handleStep}
    />
  )
}

export default ResultCar
