import React from 'react'
import { Footer, Header } from '@components'
import { TransitionGroup, Transition as ReactTransition } from 'react-transition-group'
import * as st from '@assets/styl/layout.module.styl'

const timeout = 150
const styles = {
  entering: {
    opacity: 0
  },
  entered: {
    transition: `opacity ${timeout}ms ease-in-out`,
    opacity: 1
  },
  exiting: {
    transition: `opacity ${timeout}ms ease-in-out`,
    opacity: 0
  }
}

export default ({ children, location: { pathname } }) => <>
  <Header home={pathname === '/'} fixed />
  <div className={st.core}>
    <TransitionGroup>
      <ReactTransition
        key={pathname}
        timeout={{ enter: timeout, exit: timeout }}
      >
        {status =>
          <div style={styles[status]}>
            {children}
          </div>
        }
      </ReactTransition>
    </TransitionGroup>
  </div>
  <Footer home={pathname === '/'} />
</>