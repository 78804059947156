import React from 'react'
import Helmet from 'react-helmet'
import icon from '@assets/favicon.ico'
import ogImg from '@assets/img/opengraph.jpg'

type HeadProps = {
  title?: string,
  description?: string,
  ogDescription?: string,
  path: string
}

const URL = 'https://www.wecharge.com.br'

export default ({ title, description, ogDescription, path }: HeadProps) => <Helmet htmlAttributes={{ lang : 'pt-BR' }}>
  <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
  <meta property="og:image" itemProp="image" content={URL + ogImg} />
  <meta name="twitter:image" content={URL + ogImg} />
  <title>{title || 'WeCharge: rede de eletropostos e mobilidade elétrica'}</title>
  <meta charSet="utf-8" />
  <meta name="description" content={description || 'Procurando eletropostos? Carregue seu carro elétrico ou híbrido, independente da marca e modelo, de forma confiável, de qualidade e com energia limpa.'} />
  <meta name="keywords" content="carregamento carro elétrico, carregamento carro híbrido, recarga carro elétrico, recarga carro híbrido, eletropostos, carro elétrico, abastecimento elétrico, carregador elétrico empresarial, carregador elétrico doméstico, wallbox"></meta>
  <meta property="og:title" content={title || 'WeCharge: rede de eletropostos e mobilidade elétrica'} />
  <meta property="og:site_name" content="Wecharge" />
  <meta property="og:description" content={ogDescription || description || 'Carregue seu carro elétrico ou híbrido, independente da marca e modelo, de forma confiável, de qualidade e com energia limpa.'} />
  <meta property="og:url" content={URL + path} />
  <meta property="og:locale" content="pt_BR" />
  <link rel="canonical" href={URL + path} />
  <link rel="icon" type="image/x-icon" href={URL + icon} />
  <script type="application/ld+json">
    {JSON.stringify({
      "@context": "http://schema.org",
      "@type": "WebSite",
      "name": "WeCharge",
      "url": URL
    })}
  </script>
</Helmet>