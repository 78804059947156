// extracted by mini-css-extract-plugin
export var btn = "FAQ-module--btn--e8d00";
export var btnWrapper = "FAQ-module--btn-wrapper--4456b";
export var checkboxWrapper = "FAQ-module--checkbox-wrapper--25c12";
export var checkboxWrapper2 = "FAQ-module--checkboxWrapper2--36bb4";
export var content = "FAQ-module--content--44a9b";
export var core = "FAQ-module--core--84fca";
export var formGroup = "FAQ-module--form-group--fdac7";
export var formWrapper = "FAQ-module--form-wrapper--0d622";
export var questions = "FAQ-module--questions--ebfa1";
export var text = "FAQ-module--text--e2f9b";
export var wide = "FAQ-module--wide--9e259";
export var wppBtn = "FAQ-module--wpp-btn--2ef04";